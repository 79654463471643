$yellow: #FBD800
$orange: #FFB158
$black: #252525
$white: #fff
$grey: #d9d9d9

body
  transition: 0.2s
  
.light
  background: $white
  color: $black

  header
    background: $white !important

  #theme-toggle-btn
    background-image: url("./images/moon.png") !important

  .btn-form-container
    background: $white !important

.dark
  background: $black
  color: $white

  header
    background: $black !important

  #theme-toggle-btn
    background-image: url("./images/sun.png") !important

  .btn-form-container
    background: $black !important

.App
  padding: 1rem
  box-sizing: border-box

  #theme-toggle-btn
    height: 35px
    width: 35px
    background: transparent
    border: none
    transition: 0.2s
  
  header
    position: fixed
    width: calc( 100% - 2rem )
    top: 0
    left: 0
    padding: 1rem
    background: $black
    transition: 0.2s
    z-index: 50

    .header-main
      display: flex
      align-items: center
      justify-content: space-between

    h1
      font-size: 20px
      font-weight: 600
      margin-top: 2rem
      margin-bottom: 0

  main

    .todo-list
      list-style: none
      padding: 0
      display: flex
      flex-direction: column
      gap: 1rem
      margin-bottom: 8rem
      margin-top: 8rem

      .todo-item
        background: $grey
        padding: 1rem
        border-radius: 12px
        color: $black
        display: flex
        justify-content: space-between
        align-items: center
        height: 80px
        box-sizing: border-box
        line-height: 1.1
        gap: 4rem

        .todo-done-title
            text-decoration-line: line-through
            -webkit-text-decoration-line: line-through
            text-decoration-color: $yellow
            -webkit-text-decoration-color: $yellow
            text-decoration-thickness: 2px 

        .btn-container
          display: flex
          align-items: center
          gap: 1rem

          button
            background: none
            padding: 0
            border: none
            display: grid
            place-items: center

          .change-btn
            width: 24px
            height: 24px

          .delete-todo-btn
            border-radius: 50px
            width: 24px
            height: 24px
            border: 1px solid $black
            transition: 0.2s

          .todo-done-btn
            background: url("./images/checked.png"), $yellow
            background-position: center
            background-repeat: no-repeat

  .edit-popup
    width: 100%
    height: 100%
    position: fixed
    top: 0
    left: 0
    display: grid
    place-items: center
    backdrop-filter: blur(5px)
    z-index: 100

    .edit-form
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: center
      width: calc( 100% - 2rem )
      background: linear-gradient(115deg, $yellow 60%, $orange 100%)
      padding: 3rem 1rem
      border-radius: 12px
      box-sizing: border-box
      gap: 1.5rem

      input
        background: $white
        border: none
        padding: 1rem 0.75rem
        border-radius: 8px
        font-size: 1rem
        outline: none
        width: calc( 100% - 2rem )

      .save-btn
        color: $black
        border: none
        background: $white
        padding: 10px
        border-radius: 8px
        font-size: 1rem
        width: 45%
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.20)

  .btn-form-container
    position: fixed
    bottom: 0
    left: 0
    width: calc( 100% - 2rem )
    background: $black
    padding: 1rem
    transition: 0.2s
    z-index: 50
    
    .btn-form
      display: flex
      justify-content: space-between
      align-items: center
      width: 100%
      background: linear-gradient(115deg, $yellow 60%, $orange 100%)
      padding: 0.5rem
      border-radius: 12px
      box-sizing: border-box
      left: 1rem

      input
        background: $white
        border: none
        padding: 10px
        border-radius: 8px
        font-size: 1rem
        outline: none

      .add-todo-input-field::placeholder
        font-style: italic

      .save-btn
        color: $black

      button
        border: none
        background: $white
        padding: 10px
        border-radius: 8px
        font-size: 1rem

      .add-todo-btn
        height: 38px
        width: 38px
        background-image: url("./images/cross.png")
        background-position: center
        background-repeat: no-repeat

.header-logo-btn-container
  display: flex
  align-items: center
  gap: 12px

  button
    padding: 0
    background: none
    border: none
    height: 24px

.info-screen
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: $black
  z-index: 1000
  padding: 16px
  box-sizing: border-box
  line-height: 1.2

  .close-btn
    position: absolute
    width: 48px
    height: 48px
    background: linear-gradient(115deg, $yellow 60%, $orange 100%)
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50px
    border: none
    right: 16px
    top: 16px

  h1
    color: $white

  p
    color: $yellow

  h3
    margin-top: 48px
    color: $white

  ol
    display: flex
    flex-direction: column
    gap: 32px
    padding: 0 0 0 16px

    li
      color: $grey

      &::marker
        font-weight: 700
        color: $white

      ul
        padding: 0 0 0 16px
        margin-top: 8px
        display: flex
        flex-direction: column
        gap: 8px

  .get-started
    background: linear-gradient(115deg, $yellow 60%, $orange 100%)
    width: calc( 100% - 32px )
    font-size: 24px
    padding: 16px
    border-radius: 8px
    border: none
    position: absolute
    bottom: 16px
    color: $black

.no-scroll
  overflow: hidden
  height: 100vh